import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Select from 'react-select';

const statusOptions = [
  { value: 'en espera', label: 'En espera' },
  { value: 'validado', label: 'Validado' },
  { value: 'no validado', label: 'No validado' },
];

export default function UpdateStatusModal({ isOpen, toggle, selectedStatus, setSelectedStatus, handleSaveStatus }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Actualizar Status</ModalHeader>
      <ModalBody>
        <Select
          value={statusOptions.find(option => option.value === selectedStatus)}
          onChange={(option) => setSelectedStatus(option.value)}
          options={statusOptions}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveStatus}>
          Actualizar
        </Button>
      </ModalFooter>
    </Modal>
  );
}