import React from 'react'
import { Col, Container, Row, Table, FormControl, Button, InputGroup } from "react-bootstrap"
import Context from 'context/UserContext'
import api from "services/accouts";

export default function Index ({data}) {

  const [users, setUsers] = React.useState([]) 
  const [points, setpoints] = React.useState(0) 

  const [inputs, setInputs] = React.useState([]) 

  const { token } = React.useContext(Context);

  const getUsers = () => {
    api.accouts(token).then(({ data }) => {
        setUsers(data);
    });
  };

  React.useEffect(() => {
    getUsers()
  }, [])


  const handleChange = (e) => {
    setInputs({
        ...inputs,
        [e.target.name]:e.target.value
    }) 
  }

  /* const handleInput = (value) =>{
    setpoints(value)
  } */
  
  const updatePoint = (id, currentPoints, valid) => {
      
    const nameinput = 'inputPoint'+id
    let pointSend = inputs[nameinput]
    

    if( pointSend === '' || pointSend <= 0 || pointSend === undefined ){
        setInputs({
            ...inputs,
            [inputs[nameinput]]:0
        })
        alert('Error, No puede estar vacio y debes agregar un valor mayor a cero')
    }else{
        const suma = valid ? parseInt(currentPoints) + parseInt(pointSend) :  parseInt(currentPoints) - parseInt(pointSend)

        if(suma >= 0){
            api.addPointUser(token, id, suma)
            .then( (data) => {
                setInputs({
                    ...inputs,
                    [inputs[nameinput]]:0
                })
                alert(data.message)
                getUsers()
                
            }).catch(error => {
                setInputs({
                    ...inputs,
                    [inputs[nameinput]]:0
                })
                console.log(error)
            })
        }else{
            alert('No puedes tener puntos negativos')
        }
    } 

  }


  return (
    <>
    <Container>
        <Row className='justify-content-center mt-5'>
            <Col sm={10}>
                <Table responsive="sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Apellidos</th>
                        <th>Mail</th>
                        <th>Puntos Actuales</th>
                        <th>Actualizar</th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            users.length > 0 ? (
                                users.map( ({id, nombre, apellido_paterno, apellido_materno, celular, email, puntos}) =>{
                                    return (
                                        <tr key={id}>
                                        <td>{id}</td>
                                        <td>{nombre}</td>
                                        <td>{apellido_paterno + ' ' + apellido_materno}</td>
                                    
                                        <td>{email}</td>
                                        <td>
                                            <FormControl
                                                disabled
                                                type="number"
                                                value={puntos}
                                            />
                                        </td>
                                        <td>
                                        <FormControl 
                                            type="number"
                                            name={'inputPoint'+id}
                                            onChange={ handleChange }
                                        />
                                        </td>
                                        <td>
                                          
                                                
                                                    <Button className='' variant="info" onClick={(e) => {
                                                        e.preventDefault()
                                                        updatePoint(id, puntos, true)
                                                    }}>
                                                        +
                                                    </Button> 
                                                    <Button className='' variant="danger" onClick={(e) => {
                                                        e.preventDefault()
                                                        updatePoint(id, puntos, false)
                                                    }}>
                                                        -
                                                    </Button>                                            
                                        </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>
    </Container>
    </>
  )
}
