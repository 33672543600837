import React, {useState, useEffect, useRef} from "react";
import Select from "react-select";
import useProducts from "../../hooks/useProducts";
import useUser from "../../hooks/useUser";
import toast, {Toaster} from "react-hot-toast";
import Ventas from "../../components/Ventas";
import Swal from "sweetalert2";

const templete = {
  NoSerie: "",
  SelectedEquipo: "",
  PuntosSave: "",
  ImagenModelo: "",
  selectedFile: "",
  Participacion: false,
  Porcentaje: "",
  Monto: "",
};

export default function HomePage() {
  const {
    successs,
    success,
    message,
    error,
    Productos,
    Status,
    setProductos,
    ProductosAddService,
    ProductosGetService,
  } = useProducts();
  const {tokenFull, IdUser, Puntos, Dealer} = useUser();


  const [NoSerie, setNoSerie] = useState("");
  const [SelectedEquipo, setSelectedEquipo] = useState("");
  const [PuntosSave, setPuntosSave] = useState("");
  const [ImagenModelo, setImagenModelo] = useState("");
  const [Fecha, setFecha] = useState("");
  const [selectedFile, setselectedFile] = useState("");
  const ENDPOINT = process.env.REACT_APP_API_URL.substr(0, 43);
  const [Arreglo, setArreglo] = useState([templete]);
  const image = useRef(null);
  const [Boton, setBoton] = useState(true);

  const changeHandler = (event) => {
    setselectedFile(event.target.files[0]);
    //setisFilePicked(true);
  };

  const NuevaSerie = () => {
    Arreglo.forEach((element) => {
      if (
        element.NoSerie == "" ||
        (element.SelectedEquipo == "" && element.PuntosSave == "")
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          confirmButtonColor: "#3b82f6",
          text: "Agregar numero de serie valido",
        });
      } else if (
        element.NoSerie != "" ||
        (element.SelectedEquipo != "" && element.PuntosSave != "")
      ) {
        setArreglo([...Arreglo, templete]);
      }
    });
  };

  const ProductosAgregados = () => {
    if (
      Arreglo[0].NoSerie == "" ||
      (Arreglo[0].SelectedEquipo == "" && Arreglo[0].PuntosSave == "")
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: "Agregar numero de serie",
      });
    } else {
      const data = new FormData();
      data.append("user_id", IdUser);
      data.append("dealer_id", Dealer);
      data.append("productos", JSON.stringify(Arreglo));
      data.append("fecha", Fecha);
      data.append("factura", selectedFile);

      ProductosAddService(data, tokenFull);

      Swal.fire({
        html: "Espere por favor...",
        timer: 2000,
        confirmButtonColor: "#3b82f6",
        timerProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });

      setArreglo([templete]);
      setselectedFile("");
      image.current.value = "";
      setFecha("");
      // Arreglo[0].NoSerie = "";
      // Arreglo[0].SelectedEquipo = "";
      // Arreglo[0].PuntosSave = "";
      setProductos([]);
    }
  }, [message, success]);

  const handleKeyDown = (e) => {
    // Prevenir la escritura manual en el campo de entrada de fecha
    e.preventDefault();
  };

  return (
    <>
      <Toaster />
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{marginTop: 50}}>
            <h2 className="titulo2">Registro de productos</h2>
          </div>
        </div>
        <br />
        {Arreglo.map((item, index) => {
          return (
            <Ventas
              key={index}
              item={item}
              error={error}
              successs={successs}
              message={message}
              index={index}
              Arreglo={Arreglo}
              Productos={Productos}
              Status={Status}
              setArreglo={setArreglo}
              setProductos={setProductos}
              ProductosGetService={ProductosGetService}
              setBoton={setBoton}
            />
          );
        })}
        <div className="row">
          <div className="col-md-6" />
          <div className="col-md-6">
            {Boton ? (
              <>
                <div className="col-md-12 d-flex justify-content-end">
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={() => NuevaSerie()}
                  >
                    + Agregar mas productos
                  </button>
                </div>
              </>
            ) : (
              ""
            )}

<h4>
                El sistema solo acepta facturas con fecha de venta a partir del
                1 de octubre de 2024
              </h4>

            <div className="col-md-12">
              <label>Fecha Venta</label>
              <input
                  type="date"
                  min="2024-10-01"
                  id="fecha"
                  className="form-control"
                  value={Fecha}
                  onChange={(e) => setFecha(e.target.value)}
                  onKeyDown={handleKeyDown}
                />

              <br />
            </div>
            <div className="col-md-12">
              <label>Adjuntar Factura</label>
              <input
                type={"file"}
                className="form-control"
                accept="image/*,.pdf"
                onChange={changeHandler}
                ref={image}
              />
              <br />
            </div>
            <div className="col-md-12">
              <div className="d-grid gap-2">
                <button
                  className="btn btn-primary"
                  onClick={() => ProductosAgregados()}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
}
