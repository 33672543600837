import React, { useState, useEffect } from "react";
import useRanking from "../../hooks/useRanking";
import useUser from "../../hooks/useUser";
import toast, { Toaster } from "react-hot-toast";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { filterBy } from "@progress/kendo-data-query";
import "@progress/kendo-theme-default/dist/all.css";
import Select from "react-select";

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  {
    field: "folio",
    dir: "desc",
  },
];

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

// obtener mes actual
const mesActual = () => {
  let meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let d = new Date();
  let m = d.getMonth();
  return meses[m - 1];
};

const Meses = [
  { value: 4, label: "Abril" },
  { value: 5, label: "Mayo" },
  { value: 6, label: "Junio" },
  { value: 7, label: "Julio" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Septiembre" },
  { value: 10, label: "Octubre" },
  { value: 11, label: "Noviembre" },
  // { value: 12, label: "Diciembre" },
];

export default function Ventas() {
  const {
    success,
    message,
    error,
    Posicion,
    RankingGetService,
    RankingAddService,
  } = useRanking();
  const { tokenFull, IdUser, Roles } = useUser();
  const [Desde, setDesde] = useState("");
  const [Hasta, setHasta] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const ENDPOINT = process.env.REACT_APP_BASE_URL;

  const pageChange = (event) => {
    setPage(event.page);
  };

  const _export = React.useRef(null);

  const _pdfExport = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const exportPDF = () => {
    if (_pdfExport.current !== null) {
      _pdfExport.current.save();
    }
  };

  useEffect(() => {
    let fecha_actual = new Date();
    let mes = fecha_actual.getMonth();

    const data = new FormData();
    data.append("rol", 'Promotor');
    data.append("user_id", IdUser);
    data.append("desde", mes);

    RankingAddService(data, tokenFull);

    return () => {};
  }, []);

  const ProductosAgregados = () => {
    toast.loading("Registrando...");
    const data = new FormData();
    data.append("rol", 'Promotor');
    data.append("user_id", IdUser);
    data.append("desde", Desde.value);

    RankingAddService(data, tokenFull);
  };

  useEffect(() => {
    if (success) {
      toast.dismiss();
      toast.success(message);
    }
  }, [message, success]);

  return (
    <>
      <Toaster />
      <h2 className="titulo2">Lista de ganadores especializados</h2>
      <div className="row">
        <div className="col-md-4">
          <label>Mes</label>
          <Select
            value={Desde}
            onChange={(e) => {
              setDesde(e);
            }}
            options={Meses}
            placeholder={"Seleccionar mes"}
          />
        </div>
        <div className="col-md-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => ProductosAgregados()}
            style={{ marginTop: 20 }}
          >
            Buscar
          </button>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <Grid
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            filterable={true}
            filter={filter}
            onFilterChange={(e) => setFilter(e.filter)}
            pageable={true}
            data={filterBy(
              Posicion.slice(page.skip, page.take + page.skip),
              filter
            )}
            skip={page.skip}
            take={page.take}
            total={Posicion.length}
            onPageChange={pageChange}
            style={{
              height: "620px",
            }}
          >
            <GridNoRecords>No hay informacion disponible</GridNoRecords>
            <GridColumn
              field="Lugar"
              filter={false}
              filterable={false}
              format={null}
              cell={(e) => {
                let lugar = e.dataIndex + 1;

                return <td style={{ marginTop: 10 }}>{lugar}</td>;
              }}
            />
            <GridColumn
              field="Vendedor"
              filter={false}
              filterable={false}
              format={null}
              cell={(e) => {
                let nombre =
                  e.dataItem.nombre +
                  " " +
                  e.dataItem.apellido_paterno +
                  " " +
                  e.dataItem.apellido_materno;
                return <td style={{ marginTop: 10 }}>{nombre}</td>;
              }}
            />
            <GridColumn
              field="Puntos"
              filter={false}
              filterable={false}
              format={null}
              cell={(e) => {
                let total = e.dataItem.puntos;
                return (
                  <td style={{ marginTop: 10, textAlign: "right" }}>{total}</td>
                );
              }}
            />
            {Roles == "Admin" ? (
              <GridColumn
                field="Monto"
                filter={false}
                filterable={false}
                format={null}
                cell={(e) => {
                  let lugar = e.dataIndex + 1;
                  let monto = 0;
                  if (lugar >= 1 && lugar <= 10) {
                    monto = 2000;
                  } else if (lugar >= 11 && lugar <= 20) {
                    monto = 1600;
                  } else if (lugar >= 21 && lugar <= 50) {
                    monto = 900;
                  } else if (lugar >= 51 && lugar <= 70) {
                    monto = 650;
                  } else if (lugar >= 71 && lugar <= 80) {
                    monto = 400;
                  }
                  return (
                    <td style={{ marginTop: 10, textAlign: "right" }}>
                      {"$" + formatNumber(monto)}
                    </td>
                  );
                }}
              />
            ) : (
              ""
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}
