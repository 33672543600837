import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import useUser from "../../hooks/useUser";
import useRanking from "../../hooks/useRanking";
import "./dashboard_vendedor.css";
import usuario from "../../assets/images/Usuario.png";
import footer from "../../assets/images/Footer.png";
import pasos from "../../assets/images/banner-web-puntos-naranjas2.png";
import puntos_naranjas from "../../assets/images/campana-sep-2024.jpeg";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";
import Escolaridad from "components/Escolaridad";
import terminos from "../../assets/Terminos-y-Condiciones-Puntos-Naranjas.pdf";
import finDelPrograma from "../../assets/images/finDelPrograma.png";


export default function Dashboard() {
  const { tokenFull, IdUser, Puntos, Roles, UserNombre, Foto, Concentrados } =
    useUser();
  const { error, loading, message, Posicion, RankingGetService } = useRanking();
  const [Vista, setVista] = useState(0);
  const navigate = useNavigate();
  const [CurrentPagina, setCurrentPagina] = useState(1);
  const [Pagina, setPagina] = useState(0);

  const goToRoute = (ruta) => {
    navigate("/" + ruta);
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const ENDPOINT = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (Posicion.length > 0) {
      setPagina(Math.round(Posicion.length / 10));
    }
  }, [Posicion]);

  const getPaginatedData = () => {
    const startIndex = CurrentPagina * 10 - 10;
    const endIndex = startIndex + 10;
    return Posicion.slice(startIndex, endIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      RankingGetService(tokenFull, Roles);
    }, 15000);
    return () => clearInterval(interval);
  }, [RankingGetService]);

  const previousPage = () => {
    setCurrentPagina((CurrentPagina) => CurrentPagina - 1);
  };

  const nextPage = () => {
    setCurrentPagina((CurrentPagina) => CurrentPagina + 1);
  };

  // abir pdf de bases y condiciones
  const abrirPDF = () => {
    Swal.fire({
      title: "Bases y condiciones",
      text: "¿Deseas descargar las bases y condiciones?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(terminos, "_blank");
      }
    });
  };

  return (
    <>
      <div className="login__background">
        <div className="container fondo_blanco">
          <div className="row">
            <div className="col-md-6">
              <h2>
                {Foto == null || Foto == "null" ? (
                  <img width={65} src={usuario} className="image_usuario" />
                ) : (
                  <img
                    width={65}
                    src={ENDPOINT + "/images/foto/" + Foto}
                    className="image_usuario rounded-circle"
                  />
                )}

                {UserNombre}
              </h2>
            </div>
            <div className="col-md-6 text-end">
              {Roles === "Vendedor" && Concentrados > 0 ? (
                <>
                  <h2>
                    <Link to="/canjear">
                      <span className="badge bgs">
                        ${formatNumber(Concentrados)}
                        <br />
                        ¡Canjea tu dinero en efectivo!{" "}
                      </span>
                    </Link>
                  </h2>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-dark"
                style={{ marginBottom: 20 }}
                onClick={() => goToRoute("dashboard")}
              >
                Mis ventas
              </button>
              {/* <button type="button" className="btn btn-dark" style={{marginBottom: 20}} onClick={() => goToRoute('premios')}>¿Cómo canjeo?</button> */}

              {Roles === "Vendedor" ? (
                <>
                  <button
                    type="button"
                    className="btn btn-dark"
                    style={{ marginBottom: 20 }}
                    onClick={() => goToRoute("registrar-producto")}
                  >
                    Registra tu venta
                  </button>
                </>
              ) : (
                ""
              )}

              <button
                type="button"
                className="btn btn-dark"
                style={{ marginBottom: 20 }}
                onClick={() => abrirPDF()}
              >
                Bases y condiciones
              </button>
              <button
                type="button"
                className="btn btn-dark"
                style={{ marginBottom: 20 }}
                onClick={() => goToRoute("editar-cuenta")}
              >
                Editar mis datos
              </button>
              <button
                type="button"
                className="btn btn-dark"
                style={{ marginBottom: 20 }}
                onClick={() => goToRoute("dashboard")}
              >
                Lista de ganadores
              </button>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-md-12">
                <>
{/*                   <img
                    className="img-fluid"
                    src={puntos_naranjas}
                    alt="promociones"
                    onClick={() => goToRoute("dashboard")}
                    style={{ cursor: "pointer" }}
                  /> */}
                  <br />
                  <br />
                  <img
                    className="img-fluid"
                    src={pasos}
                    alt="promociones"
                    style={{ cursor: "pointer" }}
                  />
                </>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-5 mx-auto">
              <h2 className="titulo">¿Cómo participar?</h2>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=U_cdkEYRWx8"
                width="100%"
                style={{ border: "5px solid rgb(237, 113, 0)" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h2 className="titulo">Ranking de ventas</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h6 className="text-end">
                Este ranking se actualiza cada 15 segundos
              </h6>
              <table className="table table-striped table-bordered">
                <thead
                  style={{ background: "rgb(237, 113, 0)", color: "#fff" }}
                >
                  <tr style={{ borderBottom: "2px solid rgb(237, 113, 0)" }}>
                    <th>Lugar</th>
                    <th>Vendedor</th>
                    <th>Puntos para calificar</th>
                    {Roles === "Vendedor" ? (
                      <th>
                        Dinero en efectivo que podrías ganar ¡Sigue
                        esforzándote!
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {getPaginatedData().map((item, index) => {
                    let lugar = CurrentPagina * 10 - 10 + (index + 1);
                    let monto = 0;
                    if (lugar >= 1 && lugar <= 10) {
                      monto = 2000;
                    } else if (lugar >= 11 && lugar <= 20) {
                      monto = 1600;
                    } else if (lugar >= 21 && lugar <= 50) {
                      monto = 900;
                    } else if (lugar >= 51 && lugar <= 70) {
                      monto = 650;
                    } else if (lugar >= 71 && lugar <= 100) {
                      monto = 400;
                    }

                    if (Roles === "Vendedor") {
                      return (
                        <tr key={item.id}>
                          <td>{CurrentPagina * 10 - 10 + (index + 1)}</td>
                          <td>
                            {item.nombre} {item.apellido_paterno}{" "}
                            {item.apellido_materno}
                          </td>
                          <td className="text-center">{item.puntos}</td>
                          {Roles === "Vendedor" ? (
                            <td className="text-end">${formatNumber(monto)}</td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    } else if (Roles === "Promotor") {
                      if (item.id === IdUser) {
                        return (
                          <tr key={item.id}>
                            <td>{CurrentPagina * 10 - 10 + (index + 1)}</td>
                            <td>
                              {item.nombre} {item.apellido_paterno}{" "}
                              {item.apellido_materno}
                            </td>
                            <td className="text-center">{item.puntos}</td>
                            {Roles === "Vendedor" ? (
                              <td className="text-end">
                                ${formatNumber(monto)}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      }
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-light"
                  style={{ background: "#ddd" }}
                  type="button"
                  disabled={CurrentPagina > 1 ? false : true}
                  onClick={previousPage}
                >
                  Página Anterior
                </button>
                <button
                  className="btn btn-light"
                  style={{ background: "#ddd" }}
                  type="button"
                  onClick={nextPage}
                >
                  Página Siguiente
                </button>
              </div>
            </div>
          </div>
          <Escolaridad />
        </div>
      </div>
    </>
  );
}
