import { useCallback, useState } from "react";
import { get_accessories  } from "../services/accessories";
import useUser from '../hooks/useUser';


const useAccessories = () => {
    const { tokenFull } = useUser();

  const getAccessories = useCallback(async () => {
    const response = await get_accessories(tokenFull);
    return response;
  }, []);

  return { getAccessories };
};

export default useAccessories;