import { useContext, useState } from "react";
import * as yup from "yup";
import accoutsService from "services/accouts";
import Context from "context/UserContext";
import useUser from "./useUser";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";

const schema = yup.object().shape({
  cumpleanos: yup.string().required("El campo es obligatorio"),
  escolaridad: yup.string().required("El campo es obligatorio"),
});

export default function useEscolaridad() {
  const {
    control,
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const { token } = useContext(Context);
  const { tokenFull, IdUser, Roles } = useUser();

  const get_escolaridad = async () => {
    /*     accoutsService.getEscolaridad(token,IdUser).then(({ data }) => {
      console.log(data);
    }); */

    const { data } = await accoutsService.accout(token, IdUser);
    return data;
  };

  const onSubmit = (values) => {
    const { cumpleanos } = values;
    const fecha = new Date(cumpleanos);
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const anio = fecha.getFullYear();
    const birthdate = `${anio}-${mes}-${dia}`;

    const data = { ...values, cumpleanos: birthdate };
    setLoading(true);
    accoutsService
      .editUser(token, IdUser, data)
      .then((data) => {
        Swal.fire(
          "Gracias por ingresar tus datos",
          "Los datos se han actualizado correctamente.",
          "success"
        );
        reset();
        closeModal();
      })
      .catch((err) => {
        alert("Error al actualizar los datos");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    isOpen,
    openModal,
    closeModal,
    onSubmit,
    control,
    register,
    errors,
    handleSubmit,
    loading,
    get_escolaridad,
  };
}
