import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  Button,
  Container,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import toast, { Toaster } from "react-hot-toast";
import useUser from "../../hooks/useUser";
import useAccessories from "../../hooks/useAccessories";
import UpdateStatusModal from "../../components/Modals/UpdateStatusModal";
import { updateAccessoryStatus } from "../../services/accessories";
import "./Accessories.css";
import ModalFactura from "../../components/Modals/ModalFactura";

const columns = [
  { id: "id", label: "Id", minWidth: 30 },
  { id: "nombre_completo", label: "Cliente", minWidth: 200 },
  { id: "nombre", label: "Accesorio", minWidth: 170 },
  { id: "descripcion", label: "Descripción", minWidth: 200 },
  { id: "monto", label: "Monto", minWidth: 100 },
  { id: "status", label: "Estatus", minWidth: 250 },
  { id: "factura", label: "Factura", minWidth: 250 },
  { id: "created_at", label: "Fecha de registro", minWidth: 100 },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function Accessories() {
  const { getAccessories } = useAccessories();
  const { token } = useUser();
  const [accessories, setAccessories] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAccessory, setSelectedAccessory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [facturaUrl, setFacturaUrl] = useState("");
  const [modalFacturaOpen, setModalFacturaOpen] = useState(false);
  
  useEffect(() => {
    const fetchAccessories = async () => {
      const response = await getAccessories();
      if (response && Array.isArray(response.data)) {
        setAccessories(response.data);
      } else {
        console.error("La respuesta de la API no es un array:", response);
      }
    };

    fetchAccessories();
  }, [getAccessories]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateStatus = (accessory) => {
    setSelectedAccessory(accessory);
    setSelectedStatus(accessory.status);
    setOpenModal(true);
  };

  const handleSaveStatus = async () => {
    try {
      await updateAccessoryStatus(selectedAccessory.id, selectedStatus, token);
      toast.success("Estatus actualizado correctamente");
      const updatedAccessories = accessories.map((accessory) =>
        accessory.id === selectedAccessory.id
          ? { ...accessory, status: selectedStatus }
          : accessory
      );
      setAccessories(updatedAccessories);
      setOpenModal(false);
    } catch (error) {
      toast.error("Error al actualizar el estatus");
    }
  };
  const handleViewFactura = (url) => {
    setFacturaUrl(url);
    setModalFacturaOpen(true);
  };  

  return (
    <Container>
      <Toaster />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <h1 style={{ fontFamily: "STIHL_title",fontWeight: "bold",marginTop: "27px",fontSize: "2rem", marginBottom:"15px" }}>Accesorios</h1>
          </Box>
        </Grid>  
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "rgb(237, 113, 0)" }}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: "normal", backgroundColor: "rgb(237, 113, 0)", color: "black", fontFamily: "STIHL_title" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accessories
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((accessory) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={accessory.id}
                        >
                          {columns.map((column) => {
                            const value = accessory[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "status" ? (
                                  <>
                                    {value === "validado" ? (
                                      <span className="badge bg-success">
                                        {value}
                                      </span>
                                    ) : value === "en espera" ? (
                                      <span className="badge bg-warning">
                                        {value}
                                      </span>
                                    ) : value === "no validado" ? (
                                      <span className="badge bg-danger">
                                        {value}
                                      </span>
                                    ) : (
                                      value
                                    )}
                                    <Button
                                      className="btn btn-secondary btn-sm mx-3"
                                      onClick={() =>
                                        handleUpdateStatus(accessory)
                                      }
                                      style={{
                                        backgroundColor: "#6c757d",
                                        borderColor: "#6c757d",
                                        color: "#fff",
                                      }}
                                    >
                                      Actualizar
                                    </Button>
                                  </>
                                ) : column.id === "created_at" ? (
                                  new Date(value).toLocaleDateString("es-ES")
                                ) : column.id === "factura" ? (
                                  <Button
                                    className="btn btn-secondary btn-sm"
                                    onClick={() => handleViewFactura(value)}
                                    style={{
                                      backgroundColor: "#6c757d",
                                      borderColor: "#6c757d",
                                      color: "#fff",
                                    }}
                                  >
                                    Ver factura
                                  </Button>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      colSpan={4}
                      count={accessories.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
        </Grid>
      </Grid>
      <UpdateStatusModal
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleSaveStatus={handleSaveStatus}
      />
      <ModalFactura
        modal={modalFacturaOpen}
        toggle={() => setModalFacturaOpen(!modalFacturaOpen)}
        facturaUrl={facturaUrl}
      />
    </Container>
  );
}
