const ENDPOINT = process.env.REACT_APP_API_URL;

export const get_accessories = async (token) => {
  const response = await fetch(`${ENDPOINT}/accessories`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "get",
  });

  if (!response.ok) {
    throw new Error("Error fetching accessories");
  }

  return response.json();
};


export const updateAccessoryStatus = async (id, status, token) => {
  const response = await fetch(`${ENDPOINT}/accessories/${id}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify({ status }),
  });

  if (!response.ok) {
    throw new Error("Error updating accessory status");
  }

  return response.json();
};