import React, { useEffect, useContext, useState } from "react";
import { Button, Container, Modal, Table } from "react-bootstrap";
import Account from "./Account";
import Context from "context/UserContext";
import api from "services/accouts";
import Seller from "./Seller";
import Note from "./Note";
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";

export default function Index() {
  const [cuentas, setCuentas] = useState([]);
  const [show, setShow] = useState(false);
  const [seller, setSeller] = useState(null);
  const [opt, setOpt] = useState(1); //opt 1 = informacion, opt 2 = notas

  const { token } = useContext(Context);

  useEffect(() => {
    api.accouts(token).then(({ data }) => {
      setCuentas(data);
    });
  }, [token]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const get_accounts = () => {
    api.accouts(token).then(({ data }) => {
      setCuentas(data);
    });
  };

  const delete_account = (id) => {
    const opcion = window.confirm("¿Estas seguro de eliminar?");
    if (opcion === true) {
      api.deleteUser(token, id).then((data) => {
        get_accounts();
      });
    }
  };
  const active_account = (id, value) => {
    const opcion = window.confirm(
      `¿Estas seguro de ${value === "1" ? "desactivar" : "activar"} la cuenta?`
    );
    if (opcion === true) {
      api.activeUser(token, id, !(value === "1")).then((data) => {
        get_accounts();
      });
    }
  };
  const handleModal = (id) => {
    setOpt(1);
    handleShow();
    setSeller(cuentas.find((item) => item.id === id));
  };
  const addNote = (id) => {
    setOpt(2);
    handleShow();
    setSeller(cuentas.find((item) => item.id === id));
  };

  const handleSave = (id, note) => {
    api.addNoteUser(token, id, note).then((data) => {

      get_accounts();

      Swal.fire({
        icon: "success",
        title: "Éxito",
        confirmButtonColor: "#3b82f6",
        text: "Se ha actualizado exitosamente.",
      });
      handleClose(false);
    });
  };
  const accounts = cuentas.map((item, num) => (
    <Account
      num={num + 1}
      key={item.id}
      {...item}
      onDelete={delete_account}
      onActive={active_account}
      onAddNote={addNote}
      handleModal={handleModal}
    />
  ));

  const ExportExcel = (array) => {
    const newArray = array.map((res, index) => {
      delete res.created_at;
      res.id = index + 1;
      res.status = res.status === 1 ? "Activo" : "Inactivo";
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-de-vendedores" + fileExtension);
  };

  return (
    <Container className="mt-5">
      <div className="row">
        <div className="col-md-6">
          <h1>Listado de vendedores</h1>
        </div>
        <div className="col-md-6 text-end">
          <button
            className="btn btn-primary"
            onClick={() => ExportExcel(cuentas)}
          >
            Descargar cuentas
          </button>
        </div>
      </div>
      <br />
      <br />
      <Table striped bordered hover responsive>
        <thead style={{ background: "rgb(237, 113, 0)", color: "#fff" }}>
          <tr style={{ borderBottom: "2px solid rgb(237, 113, 0)" }}>
            <th>#</th>
            <th>Nombre</th>
            <th># SAP</th>
            <th>Información</th>
            <th>Activar cuenta</th>
            <th>Seguimiento</th>
            <th>Nota</th>
            <th>Fecha de registro </th>
{/*             <th>Acciones </th> */}
          </tr>
        </thead>
        <tbody>{accounts}</tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{opt === 1 ? "Información" : "Notas"} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {opt === 1 ? (
            <Seller info={seller} />
          ) : (
            <Note info={seller} handleSave={handleSave} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

